import { defineStore } from 'pinia'
import {RouteLocationNormalized} from "vue-router";

export const useApplication = defineStore('application', {
    state: () => {
        return {
            isLoaded: false,
            isAuthenticated: false,
            route: {} as RouteLocationNormalized | {name: ''} as any,
            ui: {
                activeGlobalTab: 'Home',
                activeEntityTab: 'Dashboard',
                isEntityMode: false,
                isUserSettingsMode: false,
                activeUserSettingTab: 'General',
                entityName: '',
                entity: {
                    people: {
                        tab: 'members'
                    }
                },
                people: {
                    tab: 'members' //members, groups, requests
                },
                user: {
                    communications: {
                        tab: ''
                    },
                    scheduling: {
                        tab: ''
                    },
                    memberships: {
                        tab: 'memberships' //memberships, requests
                    },
                    mobileSettingsTab: 'unset'
                }
            }
        }
    },
    actions: {
        setLoadingState: function (loadingState: boolean){
            this.isLoaded = loadingState
        },
        setAuthenticatedState:  function (authenticatedState: boolean){
            this.isAuthenticated = authenticatedState
        },
        setRoute(route){
            this.route = route
        },
        changeGlobalTab(tabName: string){
            this.ui.activeGlobalTab = tabName
            this.ui.isUserSettingsMode = tabName === 'Settings'
        },
        changeEntityTab(tabName: string){
            this.ui.activeEntityTab = tabName
        },
        changeUserSettingTab(tabName: string){
            this.ui.activeUserSettingTab = tabName
        },
        changeEntityMode(isEntityMode: boolean){
            this.ui.isEntityMode = isEntityMode
        },
        updateEntityName(name: string){
            this.ui.entityName = name
        },
        changePeopleTab(tab: string){
            this.ui.people.tab = tab
        },
        changeUserMembershipsTab(tab: string){
            this.ui.user.memberships.tab = tab
        },
        setUserMobileSettingsTab(tab){
            this.ui.user.mobileSettingsTab = tab
        },
    },
    getters: {
        peopleCurrentTab: (state) => {
            return state.ui.people.tab
        },
        userMembershipsCurrentTab: (state) => {
            return state.ui.user.memberships.tab
        },
        isEntityMode: (state) => {
            return state.route.name.indexOf('production') > -1 || state.route.name.indexOf('organization') > -1
        },
        isProduction: (state) => {
            return state.route.name.indexOf('production') > -1
        },
    }
})
