import {CreateProductionCommand} from "@/models/productions/CreateProductionCommand";
import {apiClient} from "@/modules/apiClient";
import {Production} from "@/models/productions/Production";
import {AxiosResponse} from "axios";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {AvatarViewModel} from "@/models/AvatarViewModel";
import {UpdateProductionCommand} from "@/models/productions/UpdateProductionCommand";
import {ExportProductionMeasurementsRequest} from "@/models/productions/ExportProductionMeasurementsRequest";
import {UserMeasurement} from "@/models/user/UserMeasurement";

export const ProductionService = {
    list(): Promise<AxiosResponse<Array<ProductionMembership>>> {
        return apiClient.get(`/productions`)
    },
    fetch(productionId: string): Promise<AxiosResponse<Production>> {
        return apiClient.get(`/productions/${productionId}`)
    },
    create(command: CreateProductionCommand, organizationId: string): Promise<AxiosResponse<Production>> {
        return apiClient.post(`/organizations/${organizationId}/productions`, command)
    },
    update(productionId: string, command: UpdateProductionCommand): Promise<AxiosResponse> {
        return apiClient.put(`/productions/${productionId}`, command)
    },
    fetchOnboardStatus(productionId: string): Promise<AxiosResponse<any>> {
        return apiClient.get(`/productions/${productionId}/onboard`)
    },
    uploadPhoto(productionId: string, formData): Promise<AxiosResponse<AvatarViewModel>> {
        return apiClient.put(`/productions/${productionId}/photo`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    removePhoto(productionId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/productions/${productionId}/photo`)
    },
    exportProductionMeasurements(command: ExportProductionMeasurementsRequest): Promise<AxiosResponse> {
        return apiClient.get(`/productions/${command.productionId}/measurements/export?format=${command.format}`, {
            responseType: 'blob',
            params: {groups: command.groups}
        })
    },
    listProductionMeasurements(productionId: string): Promise<AxiosResponse<Array<UserMeasurement>>> {
        return apiClient.get(`/productions/${productionId}/measurements`)
    },
}
