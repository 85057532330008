import { RouteRecordRaw } from "vue-router";

export const productionRoutes: RouteRecordRaw[] = [
    {
        path: "/productions/:productionId",
        name: "production-overview",
        props: true,
        component: () => import("@/views/productions/ProductionOverview.vue"),
        meta: {
            pageName: 'Production Overview',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**'
        }
    },
    {
        path: "/productions/:productionId/onboarding",
        name: "production-onboarding",
        props: true,
        component: () => import("@/components/Onboarding/ProductionOnboarding.vue"),
        meta: {
            pageName: 'Production Onboarding',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/onboard'
        }
    },
    {
        path: "/productions/:productionId/schedule",
        name: "production-schedule",
        props: true,
        component: () => import("@/components/Entity/SchedulingTab.vue"),
        meta: {
            pageName: 'Production Schedule',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/schedule'
        }
    },
    {
        path: "/productions/:productionId/communications",
        name: "production-communications",
        props: true,
        component: () => import("@/components/Entity/CommunicationsTab.vue"),
        meta: {
            pageName: 'Production Communications',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/communications'
        }
    },
    {
        path: "/productions/:productionId/availability",
        name: "production-availability",
        props: true,
        component: () => import("@/components/Entity/AvailabilityTab.vue"),
        meta: {
            pageName: 'Production Availability',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/availability'
        }
    },
    {
        path: "/productions/:productionId/settings",
        name: "production-settings",
        props: true,
        component: () => import("@/components/Entity/SettingsTab.vue"),
        meta: {
            pageName: 'Production Settings',
            //permission: RoutePermission.Manage,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/settings'
        },
        children: [
            {
                path: "",
                name: "production-settings-home",
                props: true,
                component: () => import("@/components/Entity/EntitySettingsMenuMobile.vue"),
                meta: {
                    pageName: 'Production Settings',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/general'
                }
            },
            {
                path: "general",
                name: "production-settings-general",
                props: true,
                component: () => import("@/components/Entity/Settings/GeneralSettings.vue"),
                meta: {
                    pageName: 'Production Settings General',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/general'
                }
            },
            {
                path: "logo",
                name: "production-settings-logo",
                props: true,
                component: () => import("@/components/Entity/Settings/LogoSettings.vue"),
                meta: {
                    pageName: 'Production Settings Logo',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/logo'
                }
            },
            {
                path: "venues",
                name: "production-settings-venues",
                props: true,
                component: () => import("@/components/SelfContained/VenueSettings.vue"),
                meta: {
                    pageName: 'Production Settings Venues',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/venues'
                }
            },
            {
                path: "call-types",
                name: "production-settings-call-types",
                props: true,
                component: () => import("@/components/SelfContained/CallTypeSettings.vue"),
                meta: {
                    pageName: 'Production Settings Call Types',
                    //permission: RoutePermission.Manage,
                    permissionId: 'productionId',
                    friendlyPath: '/productions/**/settings/call-types'
                }
            }
        ]
    },
    {
        path: "/productions/:productionId/people",
        name: "production-people",
        props: true,
        component: () => import("@/components/Entity/PeopleTab.vue"),
        meta: {
            pageName: 'Production People',
            //permission: RoutePermission.Access,
            permissionId: 'productionId',
            friendlyPath: '/productions/**/people'
        }
    }
];
