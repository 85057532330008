import { RouteRecordRaw } from "vue-router";

export const userRoutes: RouteRecordRaw[] = [
    {
        path: "/schedule",
        name: "user-schedule",
        props: true,
        component: () => import("@/components/User/CalendarTab.vue"),
        meta: {
            pageName: 'User Schedule',
            friendlyPath: '/user/schedule'
        }
    },
    {
        path: "/",
        name: "user-overview",
        props: true,
        component: () => import("@/components/User/OverviewTab.vue"),
        meta: {
            pageName: 'User Overview',
            friendlyPath: '/user'
        }
    },
    {
        path: "/communications",
        name: "user-communications",
        props: true,
        component: () => import("@/components/User/CommunicationTab.vue"),
        meta: {
            pageName: 'User Communications',
            friendlyPath: '/user/communications'
        }
    },
    {
        path: "/memberships",
        name: "user-memberships",
        props: true,
        component: () => import("@/components/User/EntitiesTab.vue"),
        meta: {
            pageName: 'User Memberships',
            friendlyPath: '/user/memberships'
        }
    },
    {
        path: "/user-onboarding",
        name: "user-onboarding",
        props: true,
        component: () => import("@/components/Onboarding/UserOnboarding.vue"),
        meta: {
            pageName: 'User Onboarding',
            friendlyPath: '/user/onboarding'
        }
    }
];
