
import {useUser} from "@/store/pinia/userStore";
import {defineComponent} from "vue";

export default defineComponent({
  name: "ApplicationWrapper",
  setup(){
    const userStore = useUser()
    return {userStore}
  },
  mounted() {
    this.userStore.fetchUser()
    //console.log('[SH] ApplicationWrapper Mounted', this.userStore.fetchComplete)
  }
})
