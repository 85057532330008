import { RouteRecordRaw } from "vue-router";

export const userSettingRoutes: RouteRecordRaw[] = [
    {
        path: "/settings/password",
        name: "user-settings-password",
        props: true,
        component: () => import("@/components/User/Settings/UserChangePasswordSettings.vue"),
        meta: {
            pageName: 'User Change Password',
            friendlyPath: '/user/settings/password'
        }
    },
    {
        path: "/settings/conflicts",
        name: "user-settings-conflicts",
        props: true,
        component: () => import("@/components/User/Settings/UserConflictSettings.vue"),
        meta: {
            pageName: 'User Conflicts',
            friendlyPath: '/user/settings/conflicts'
        }
    },
    {
        path: "/settings/emergency-contacts",
        name: "user-settings-emergency-contacts",
        props: true,
        component: () => import("@/components/User/Settings/UserEmergencyContactsCard.vue"),
        meta: {
            pageName: 'User Emergency Contacts',
            friendlyPath: '/user/settings/emergency-contacts'
        }
    },
    {
        path: "/settings/measurements",
        name: "user-settings-measurements",
        props: true,
        component: () => import("@/components/User/Settings/UserSettingsMeasurements.vue"),
        meta: {
            pageName: 'User Measurements',
            friendlyPath: '/user/settings/measurements'
        }
    },
    {
        path: "/settings",
        name: "user-settings-general",
        props: true,
        component: () => import("@/components/User/Settings/UserGeneralSettings.vue"),
        meta: {
            pageName: 'User Settings',
            friendlyPath: '/user/settings'
        }
    }
];
