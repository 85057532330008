import axios from "axios";
import {auth0} from "@/main";

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use((config) => {
    //console.log(`[SH] axios ${config.url}`);
    return auth0.getAccessTokenSilently().then(tokenResponse => {
        //console.log('[SH] getAccessTokenSilently success', tokenResponse)
        // console.log(`[SH] SUCCESS TOKEN ${config.url}`);
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${tokenResponse}`;
        return Promise.resolve(config);
    }).catch((err) => {
        //console.log('[SH] getAccessTokenSilently error', err)
        // console.log(`[SH] FAILED TOKEN ${config.url}`, err);
        // // Auth.Instance.signIn();
        // if((err as Error).message === 'Unable To Obtain Valid Token') {
        //     Auth.Instance.signIn();
        // } else {
        //     return Promise.reject(err);
        // }

        return Promise.reject(err);
    });
    /*
        return msalService.getAccessToken().then((token) => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return Promise.resolve(config);
        });
     */
}, (error) => {
    return Promise.reject(error);
});

/*
apiClient.interceptors.response.use((response) => {
    return Promise.resolve(response)
}, (error) => {
    if ((process.env.VUE_APP_SH_ENV === 'Local' || process.env.VUE_APP_SH_ENV === 'Development')) {
        try {
            bus.emit('modal:exception:show', error.response.data)
            // eslint-disable-next-line no-empty
        }catch (e){}
    }
    if (process.env.VUE_APP_ANALYTICS === 'enabled') {
        try {
            const problemDetails = error.response.data as ProblemDetails;
            openReplayTracker.issue(problemDetails.title, problemDetails);
            // eslint-disable-next-line no-empty
        }catch (e){}
    }
    return Promise.reject(error);
})*/
