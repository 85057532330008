import {defineStore} from 'pinia'
import {User} from "@/models/user/User";
import {Invitation} from "@/models/user/Invitation";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {MembershipRole} from "@/models/enums/Role";
import {useApplication} from "@/store/pinia/applicationStore";
import {NotificationItem} from "@/models/notifications/Notification";
import {UserService} from "@/services/UserService";
import router from "@/router";
import {ProductionService} from "@/services/ProductionService";
import {OrganizationService} from "@/services/OrganizationService";
import {WebAnalytics} from "@/modules/webAnalytics";
import moment from "moment";
import {getGenderIdentity} from "@/models/enums/GenderIdentity";
import {getPrimaryIndustry} from "@/models/enums/PrimaryIndustry";
import {getInitialPersona} from "@/models/enums/InitialPersona";
import {getUserType} from "@/models/enums/UserType";
import posthog from "posthog-js";
import {UserMetadata} from "@/models/user/UserMetadata";

export const useUser = defineStore('user', {
    state: () => {
        return {
            profile: {} as User,
            metadata: {} as UserMetadata,
            invitations: [] as Array<Invitation>,
            productions: [] as Array<ProductionMembership>,
            organizations: [] as Array<OrganizationMembership>,
            notifications: [] as Array<NotificationItem>,
            orgFetchComplete: false,
            prodFetchComplete: false,
            userFetchComplete: false,
        }
    },
    actions: {
        fetchUser: function () {
            const applicationStore = useApplication()
            //console.log('[SH] fetchUser')
            //console.trace('[SH] fetchUser trace')
            UserService.fetch().then(response => {
                if (!response.data.userExists) {
                    router.push({name: 'sign-up'})
                    applicationStore.setLoadingState(true)
                } else {
                    this.profile = response.data
                    applicationStore.setLoadingState(true)
                    applicationStore.setAuthenticatedState(true)
                    this.fetchInvitations()
                    this.fetchProductionMemberships()
                    this.fetchOrganizationMemberships()
                    this.fetchNotifications()
                    this.userFetchComplete = true

                    WebAnalytics.identify({
                        id: this.profile.accountId,
                        email: this.profile.emailAddresses.filter(x => x.isPrimary)[0].email,
                        firstName: this.profile.firstName,
                        lastName: this.profile.lastName,
                        name: `${this.profile.firstName} ${this.profile.lastName}`,
                        title: this.profile.title,
                        avatar: this.profile.avatar,
                        $avatar: this.profile.avatar,
                        StageCode: this.profile.stageCode,
                        createdAt: moment(this.profile.createdOn).format(),
                        pronouns: this.profile.pronouns,
                        gender: getGenderIdentity(this.profile.genderIdentity).name,
                        birthday: moment(this.profile.birthdate).format(),
                        age: moment().diff(moment(this.profile.birthdate), 'years', false),
                        industry: getPrimaryIndustry(this.profile.industry).name,
                        initialPersona: getInitialPersona(this.profile.initialPersona).name,
                        userType: getUserType(this.profile.userType).name
                    });

                    UserService.fetchMetadata().then(response => {
                        this.metadata = response.data
                    })

                    UserService.reconcile()

                }
            });
        },
        fetchInvitations: function () {
            UserService.fetchInvitations().then(response => {
                this.invitations = response.data
            })
        },
        fetchProductionMemberships: function () {
            ProductionService.list().then(response => {
                this.productions = response.data
            }).then(() => {
                this.prodFetchComplete = true
            })
        },
        fetchOrganizationMemberships: function () {
            OrganizationService.list().then(response => {
                this.organizations = response.data
            }).then(() => {
                this.orgFetchComplete = true
            })
        },
        fetchNotifications: function () {
            UserService.fetchNotifications().then(response => {
                this.notifications = response.data
            })
            if (process.env.VUE_APP_SH_ENV !== 'Local') {
                const $this = this
                setInterval(function () {
                    UserService.fetchNotifications().then(response => {
                        $this.notifications = response.data
                    })
                    $this.fetchProductionMemberships()
                    $this.fetchOrganizationMemberships()
                }, 15000)
            }
        },
        setNotificationAsRead: function (notificationId: string) {
            UserService.markReadNotifications([notificationId]).then(() => {
                this.notifications.forEach(notification => {
                    if (notification.notificationId === notificationId) {
                        notification.read = true
                    }
                })
            })
        },
        markAllNotificationsRead: function() {
            // Extract all notificationIds from this.notifications array
            const notificationIds = this.notifications.map(notification => notification.notificationId);

            // Pass the notificationIds array to the UserService
            UserService.markReadNotifications(notificationIds).then(() => {
                this.notifications.forEach(notification => {
                    // Check if the notificationId is in the notificationIds array
                    if (notificationIds.includes(notification.notificationId)) {
                        notification.read = true;
                    }
                });
            });
        }
        ,
        lookupProductionId(productionId) {
            try {
                return this.productions.find(production => production.productionId === productionId);
            } catch (ex) {
                return null;
            }
        },
        lookupOrganizationId(organizationId) {
            try {
                return this.organizations.find(organization => organization.organizationId === organizationId);
            } catch (ex) {
                return null;
            }
        },
        canAccess(entityId) {
            const production = this.productions.find(p => p.productionId === entityId);
            if (production) {
                return true;
            }
            const organization = this.organizations.find(p => p.organizationId === entityId);
            return !!organization;
        },
        canManage(entityId): boolean {
            const production = this.productions.find(p => p.productionId === entityId);
            if (!!production && (production.role !== MembershipRole.Member)) {
                return true;
            }
            const organization = this.organizations.find(p => p.organizationId === entityId);
            return (organization && organization?.role !== MembershipRole.Member) || false;
        },
        isAdmin(entityId) {
            const production = this.productions.find(p => p.productionId === entityId);
            if (production && (production.role === MembershipRole.Administrator || production.role === MembershipRole.Owner)) {
                return true;
            }
            const organization = this.organizations.find(p => p.organizationId === entityId);
            return (organization && (organization.role === MembershipRole.Administrator || organization.role === MembershipRole.Owner));
        },
        overrideUserTerms() {
            this.profile.tosAcceptedOn = new Date()
            this.profile.privacyPolicyAcceptedOn = new Date()
        }
    },
    getters: {
        fetchComplete: (state) => {
            return state.prodFetchComplete && state.orgFetchComplete
        },
        // lookupProductionId: (state) => {
        //     return (productionId) => state.productions.find(production => production.productionId === productionId);
        // },
        // lookupOrganizationId: (state) => {
        //     return (organizationId) => state.organizations.find(organization => organization.organizationId === organizationId);
        // },
        hasAccessToEntities: (state) => {
            return state.productions.filter(p => p.status == MembershipStatus.Active).length > 0
                || state.organizations.filter(o => o.status == MembershipStatus.Active).length > 0;
        },
        canManageAny: (state) => {
            let result = state.productions.filter(p => p.role !== MembershipRole.Member).length > 0;
            if (!result) {
                result = state.organizations.filter(p => p.role !== MembershipRole.Member).length > 0;
            }
            return result;
        },
        // canAccess: (state) => {
        //     return (entityId) => {
        //         const production = state.productions.find(p => p.productionId === entityId);
        //         if (production) {
        //             return true;
        //         }
        //         const organization = state.organizations.find(p => p.organizationId === entityId);
        //         return !!organization;
        //     }
        // },
        // canManage: (state) => {
        //     return (entityId) => {
        //         const production = state.productions.find(p => p.productionId === entityId);
        //         if (production && (production.role !== MembershipRole.Member)) {
        //             return true;
        //         }
        //         const organization = state.organizations.find(p => p.organizationId === entityId);
        //         return !!(organization && (organization.role !== MembershipRole.Member));
        //     }
        // },
        // isAdmin: (state) => {
        //     return (entityId) => {
        //         const production = state.productions.find(p => p.productionId === entityId);
        //         if (production && (production.role === MembershipRole.Administrator || production.role === MembershipRole.Owner)) {
        //             return true;
        //         }
        //         const organization = state.organizations.find(p => p.organizationId === entityId);
        //         return !!(organization && (organization.role === MembershipRole.Administrator || organization.role === MembershipRole.Owner));
        //     }
        // },
        currentEntity: () => {
            const applicationStore = useApplication()
            const route = applicationStore.route
            if (route.name.indexOf('organization') > -1) {
                return route.params['organizationId'] as string
            } else {
                return route.params['productionId'] as string
            }
        },
        currentEntityObject: (state) => {
            const applicationStore = useApplication()
            const route = applicationStore.route
            if (route.name.indexOf('organization') > -1) {
                const entityId = route.params['organizationId'] as string
                return state.organizations.find(organization => organization.organizationId === entityId)
            } else {
                const entityId = route.params['productionId'] as string
                return state.productions.find(production => production.productionId === entityId)
            }
            return {organizationId: '', productionId: ''}
        },
        currentEntityName: (state) => {
            const applicationStore = useApplication()
            const route = applicationStore.route
            try {
                if (route.name.indexOf('organization') > -1) {
                    const entityId = route.params['organizationId'] as string
                    const organization = state.organizations.find(organization => organization.organizationId === entityId)
                    return organization?.name
                } else {
                    const entityId = route.params['productionId'] as string
                    const production = state.productions.find(production => production.productionId === entityId)
                    return production?.name
                }
            } catch (err) {
                return '';
            }
        },
        incomingInvitations: (state) => {
            return state.invitations.filter(x => x.status === MembershipStatus.PendingUser)
        },
        outdatedTerms: (state) => {
            if(process.env.VUE_APP_ANALYTICS === 'enabled'){
                const policyDateFlag = posthog.getFeatureFlag('policy_date') as string
                if (policyDateFlag !== '') {
                    return moment(state.profile.tosAcceptedOn).isBefore(moment(policyDateFlag)) || moment(state.profile.privacyPolicyAcceptedOn).isBefore(moment(policyDateFlag))
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
})
