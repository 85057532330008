import {appInsights, openReplayTracker} from "@/main";
import Bugsnag from '@bugsnag/js'
import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";
import {H} from "highlight.run";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {isPlatform} from "@ionic/vue";

export class WebAnalytics {
    private static dataLayerName: string = 'dataLayer';
    private static identifyEventName: string = 'identify';
    private static groupEventName: string = 'group';
    private static logoutEventName: string = 'logout';
    private static pageViewEventName: string = 'pageCall';
    private static standardEventName: string = 'customEvent';

    static identify(identity: AnalyticsIdentity) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        WebAnalytics.pushToDataLayer(this.identifyEventName, {
            userId: identity.id,
            userObject: identity
        });
        appInsights.setAuthenticatedUserContext(identity.id, undefined, true);
        Bugsnag.setUser(identity.id, identity.email, `${identity.firstName} ${identity.lastName}`);
        Sentry.setUser({
            id: identity.id,
            email: identity.email,
            username: `${identity.firstName} ${identity.lastName}`
        });
        posthog.identify(identity.id, {
            ...identity
        });
        openReplayTracker.setUserID(identity.id);
        H.identify(identity.id, {highlightDisplayName: identity.name, ...identity});
        FirebaseAnalytics.setUserId({
            userId: identity.id,
        });
        WebAnalytics.initalizeFreshchat(identity);
    }

    static identifyOrganization(identity: OrganizationIdentity) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        WebAnalytics.pushToDataLayer(this.groupEventName, {
            groupId: identity.organizationId,
            groupObject: identity
        });
        posthog.group('organization', identity.organizationId, identity)
    }

    static identifyProduction(identity: ProductionIdentity) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        WebAnalytics.pushToDataLayer(this.groupEventName, {
            groupId: identity.productionId,
            groupObject: identity
        });
        posthog.group('production', identity.productionId, identity)
    }

    static logout() {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        WebAnalytics.pushToDataLayer(this.logoutEventName);
        appInsights.clearAuthenticatedUserContext();
        posthog.reset();
        Sentry.configureScope(scope => scope.setUser(null));
    }

    static view(page: PageSpec) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        WebAnalytics.pushToDataLayer(this.pageViewEventName, page);
        appInsights.trackPageView({
            name: page.pageName,
            refUri: `${document.location.protocol}://${document.location.host}${page.fromPath}`,
            uri: `${document.location.protocol}://${document.location.host}${page.path}`
        });
        posthog.capture('$pageview');
        openReplayTracker.event('view', page.pageName);
        FirebaseAnalytics.setScreenName({
            screenName: page.pageName,
            nameOverride: page.pageName
        });
    }

    static track(event: AnalyticsEvent, payload: DataLayerSpec) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        //todo: always push the page name
        WebAnalytics.pushToDataLayer(this.standardEventName, {
            event: 'customEvent',
            eventName: event,
            eventObject: payload
        });
        posthog.capture(event, payload);
        openReplayTracker.event(event, payload);
        appInsights.trackEvent({name: event, properties: payload})
        H.track(event, {...payload})
    }

    static trackFlexible(event: string, payload: any) {
        if (process.env.VUE_APP_ANALYTICS === 'disabled') {
            return;
        }
        //todo: always push the page name
        WebAnalytics.pushToDataLayer(this.standardEventName, {
            event: 'customEvent',
            eventName: event,
            eventObject: payload
        });
        posthog.capture(event, payload);
        openReplayTracker.event(event, payload);
        appInsights.trackEvent({name: event, properties: payload})
        H.track(event, {...payload})
    }

    /* Helpers */
    /**
     * Push Payload to DataLayer
     * @description Will prepend the trigger event to the data layer payload, and push to the window object specified in {dataLayerName}
     * @param event Trigger event in tag manager
     * @param payload The object to send
     * @private
     */
    private static pushToDataLayer(event: string, payload?: object) {
        if (process.env.VUE_APP_SH_ENV === 'disabled') {
            return;
        }
        const payloadToSend = payload ? payload : {};
        payloadToSend['event'] = event;
        if (typeof window[this.dataLayerName] !== "undefined") {
            window[this.dataLayerName].push(payloadToSend);
        }
    }

    private static initalizeFreshchat(identity: AnalyticsIdentity) {

        try {
            // @ts-ignore
            window.initFreshChat = function () {
                // @ts-ignore
                window.fcWidget.init({
                    token: "927a7999-6b6d-46b1-bbbd-823fa9fcec81",
                    host: "https://wchat.freshchat.com",
                    externalId: identity.id,
                    firstName: identity.firstName,
                    lastName: identity.lastName,
                    email: identity.email,
                    pronouns: identity.pronouns,
                    avatar: identity.avatar,
                    config: {
                        headerProperty: {
                            hideChatButton: true
                        },
                        cssNames: {
                            widget: 'fc_frame',
                            open: 'fc_open',
                            expanded: 'fc_expanded'
                        },
                    }
                });
            }
            //@ts-ignore
            window.initialize = function (i, t) {
                //@ts-ignore
                let e;

                // @ts-ignore
                i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
            }
            // @ts-ignore
            window.initialize(document, "Freshdesk Messaging-js-sdk");
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    }

}

interface AnalyticsIdentity {
    id: string
    age: number
    avatar: string
    $avatar: string
    birthday: string
    createdAt: string
    email: string
    firstName: string
    lastName: string
    name: string
    gender: string
    title: string
    pronouns: string
    StageCode: string
    industry: string
    initialPersona: string
    userType: string
}

interface PageSpec {
    pageName: string
    path: string
    url: string
    fromPath: string,
    friendlyPath: string,
    friendlyUrl: string
}

interface DataLayerSpec {
    eventName: AnalyticsEvent
    wizardName?: AnalyticsWizard
    wizardStep?: string
    entity?: AnalyticsEntity
    action?: AnalyticsAction
    profileEntity?: AnalyticsProfileEntity
    //eventSource?: AnalyticsEventSource
    //linkName?: string
}

export enum AnalyticsEvent {
    Wizard = "Wizard",
    ManageEntity = "Manage Entity",
    ManageProfile = "Manage Profile",
    Login = "Login",
    Logout = "Logout",
    LinkClick = "Link Click"
}

export enum AnalyticsEntity {
    Communication = "Communication",
    Announcement = "Announcement",
    Notification = "Notification",
    Event = "Event",
    Call = "Call",
    Profile = "User Profile",
    OrgProfile = "Organization Profile",
    ProdProfile = "Production Profile",
    Report = "Report",
}

export enum AnalyticsProfileEntity {
    Venue = "Venue",
    VenueSpace = "Venue Space",
    Person = "Person",
    Group = "Group",
    Conflict = "Conflict",
    CallType = "CallType",
    ReportTemplate = "Report Template",
    EmailAddress = "Email Address",
    MailingAddress = "Mailing Address",
    PhoneNumber = "Phone Number",
    EmergencyContact = "Emergency Contact",
    MedicalNote = "Medical Note",
    PrivacySetting = "Privacy Setting",
    Password = "Password",
    GeneralSetting = "General Setting",
    Photo = "Photo"
}

export enum AnalyticsAction {
    Create = "Create",
    Read = "Read",
    Update = "Update",
    Delete = "Delete",
    Start = "Start",
    Complete = "Complete",
    Publish = "Publish",
    Verify = "Verify"
}

export enum AnalyticsWizard {
    SignUp = "Sign Up",
    ForgotPassword = "Forgot Password",
    CreateProfile = "Create Profile",
    CreateOrganization = "Create Organization",
    CreateProduction = "Create Production"
}

export enum AnalyticsEventSource {
    QuickActions = "Quick Actions",
    Dashboard = "Dashboard"
}

export interface OrganizationIdentity {
    organizationId: string
    name: string
    $name: string
    $avatar: string
    //createdAt: string
    email: string
    phone: string
    //employees: number
    type: string
    website: string
    //productionCount: number
    StageCode: string
}

export interface ProductionIdentity {
    productionId: string
    name: string
    $name: string
    $avatar: string
    //createdAt: string
    //employees: number
    type: string
    //website: string
    StageCode: string
}
