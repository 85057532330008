import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/js/utils-import';
import Select2 from 'vue3-select2-component';
import './sass/style.scss';
import './sass/plugins.scss';
//import * as Sentry from "@sentry/capacitor";
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import {Integrations} from "@sentry/tracing";
import OpenReplay from '@openreplay/tracker';
import {ApplicationInsights, DistributedTracingModes} from '@microsoft/applicationinsights-web';
import BugsnagPluginVue, {BugsnagPluginVueResult} from '@bugsnag/plugin-vue'
import posthog from 'posthog-js';
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import {VueSpinners} from '@saeris/vue-spinners'
import Popper from "vue3-popper";
import {H} from 'highlight.run';
import trackerAxios from '@openreplay/tracker-axios';
import trackerAssist from '@openreplay/tracker-assist';
import trackerVuex from '@openreplay/tracker-vuex';
import 'vue-skeletor/dist/vue-skeletor.css';
import ElementPlus from 'element-plus'
import {apiClient} from "@/modules/apiClient";
import Maska from "maska";
import Bugsnag from "@bugsnag/js";
import vfmPlugin from "vue-final-modal"
import VueLogger from "@/plugins/logger/vue-logger";
import {createPinia} from 'pinia'
import {LogLevels} from "@/plugins/logger/enum/log-levels";
import {defaultConfig, plugin} from '@formkit/vue'
import '@ionic/vue/css/core.css';
import '@formkit/themes/dist/genesis/theme.css'
// @ts-ignore
import Toast, {PluginOptions} from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueCookies from 'vue-cookies';
import {IonicVue, isPlatform} from "@ionic/vue";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import capacitorAuth from "@/modules/CapacitorAuth";
import {createAuth0} from "@auth0/auth0-vue";
import 'vue-advanced-cropper/dist/style.css';
import {VueQueryPlugin} from "@tanstack/vue-query";

declare global {
    interface Window {
        initializeKT?: any;
        KTUtil?: any;
        KTMenu?: any;
        KTApp?: any;
        KTLayoutAside?: any;
        KTLayoutExplore?: any;
        KTLayoutSearch?: any;
        KTLayoutToolbar?: any;
        newrelic?: any;
        fcWidget?: any;
        Headway?: any;
        rnw?: any;
    }
}

export const app = createApp(App);
//.mount("#app");

export const auth0 = createAuth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN || '',
    clientId: process.env.VUE_APP_AUTH0_CLIENTID || '',
    authorizationParams: {
        redirect_uri: isPlatform("capacitor")
            ? process.env.VUE_APP_AUTH0_CAPCALLBACK
            : window.location.origin,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    useRefreshTokensFallback: true
});
capacitorAuth();

export const openReplayTracker = new OpenReplay({
    // @ts-ignore
    projectKey: process.env.VUE_APP_OPENREPLAY,
    defaultInputMode: 0,
    obscureInputDates: false,
    obscureInputNumbers: false,
    resourceBaseHref: isPlatform('capacitor') ? process.env.VUE_APP_OPENREPLAY_RESOURCEURL : null,
    __DISABLE_SECURE_MODE: isPlatform('capacitor'),
    revID: process.env.VUE_APP_GITHASH,
    // @ts-ignore
    network: {
        capturePayload: true,
        sessionTokenHeader: 'X-OpenReplay-SessionToken'
    }
});

openReplayTracker.use(trackerAssist({
    callConfirm: {
        declineBtn: {
            innerHTML: 'Decline',
            className: 'btn btn-secondary text-white'
        },
        confirmBtn: {
            innerHTML: 'Answer',
            className: 'btn btn-primary text-white'
        }
    }
}));

/*const vuexPlugin = openReplayTracker.use(trackerVuex())
const userStorePlugin = vuexPlugin('USER')
userStorePlugin(useUser())
const applicationStorePlugin = vuexPlugin('APPLICATION')
applicationStorePlugin(useApplication())*/

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_APPINSIGHTSKEY,
        autoTrackPageVisitTime: true,
        distributedTracingMode: DistributedTracingModes.W3C,
        correlationHeaderDomains: ['*.stagehub.io', 'localhost', '*.stagehub.com'],
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAjaxPerfTracking: true,
        enableAjaxErrorStatusText: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        excludeRequestFromAutoTrackingPatterns: [/^((?!stagehub).)*$/gm],
        correlationHeaderExcludePatterns: [/^((?!stagehub).)*$/gm],
    }
});

if (process.env.VUE_APP_ANALYTICS === 'enabled') {
    // @ts-ignore
    posthog.init(process.env.VUE_APP_POSTHOG, {
        api_host: 'https://app.posthog.com', capture_pageview: false,
        session_recording: {
            blockClass: 'record-block',
            blockSelector: '[record-block]',
            ignoreClass: 'record-ignore',
            maskAllInputs: false,
            maskInputOptions: {},
            maskInputFn: undefined,
            slimDOMOptions: {},
            collectFonts: true
        }
    });

    if (!isPlatform('capacitor')) {
        FirebaseAnalytics.initializeFirebase({
            apiKey: process.env.VUE_APP_FB_APIKEY as string,
            authDomain: process.env.VUE_APP_FB_AUTHDOMAIN as string,
            projectId: process.env.VUE_APP_FB_PROJ as string,
            storageBucket: process.env.VUE_APP_FB_STORAGE as string,
            messagingSenderId: process.env.VUE_APP_FB_MSG as string,
            appId: process.env.VUE_APP_FB_APPID as string,
            measurementId: process.env.VUE_APP_FB_MEASURE as string,
        });
    }

    /* AppInsights */
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((envelope) => {
        if (!envelope.tags) {
            envelope.tags = [];
        }
        envelope.tags.push({"ai.cloud.role": "app-web"});
    });

    Sentry.init({
        app,
        dsn: "https://5b2a535b508c4d3a9087239380611c4d@o98448.ingest.sentry.io/6450315",
        integrations: [new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "*.stagehub.com", "*.stagehub.io", /^\//],
        })],
        replaysSessionSampleRate: 1.0,
        environment: process.env.VUE_APP_SH_ENV,
        tracesSampleRate: 1.0,
        release: process.env.VUE_APP_GITHASH
    });

    H.init(process.env.VUE_APP_HIGHLIGHT, {
        environment: process.env.VUE_APP_SH_ENV,
        enableStrictPrivacy: false,
        networkRecording: {
            recordHeadersAndBody: true,
            enabled: true,
            urlBlocklist: ['posthog'],
        },
        version: process.env.VUE_APP_GITHASH
    });

    H.getSessionURL().then(sessionUrl => {
        Sentry.setContext("highlight", {
            "url": sessionUrl
        });
        appInsights.addTelemetryInitializer((envelope) => {
            // @ts-ignore
            envelope.data.highlightURL = sessionUrl
        });
        Bugsnag.addOnError(event => {
            event.addMetadata('highlight', {
                highlightURL: sessionUrl
            })
        })
        if (window.newrelic && window.newrelic.setCustomAttribute) {
            window.newrelic.setCustomAttribute("highlightURL", sessionUrl)
        }
        apiClient.defaults.headers['X-Highlight'] = sessionUrl;
    });

    openReplayTracker.start().then(tracker => {
        appInsights.addTelemetryInitializer((envelope) => {
            // @ts-ignore
            envelope.data.openReplaySessionToken = tracker.sessionID
            // @ts-ignore
            envelope.data.openReplay = `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${tracker.sessionID}`
        });
        Bugsnag.addOnError(event => {
            event.addMetadata('special_info', {
                openReplaySessionToken: openReplayTracker.getSessionToken(),
                OpenReplay: `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`
            })
        })
        Sentry.setTag("OpenReplay", `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`)
        if (window.newrelic && window.newrelic.setCustomAttribute) {
            window.newrelic.setCustomAttribute("OpenReplay", `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`)
            window.newrelic.setCustomAttribute("openReplaySessionToken", openReplayTracker.getSessionID())
        }
        apiClient.defaults.headers['X-OpenReplay'] = `https://app.openreplay.com/${process.env.VUE_APP_OPENREPLAY_PROJECTID}/session/${openReplayTracker.getSessionID()}`;
    });

    Bugsnag.start({
        apiKey: '5a962a9945c6b533719b0b88e877bc62',
        plugins: [new BugsnagPluginVue()],
        releaseStage: process.env.VUE_APP_SH_ENV,
        enabledReleaseStages: ['Production', 'Staging', 'Development'],
        appVersion: process.env.VUE_APP_GITHASH
    });

    const bugsnagVue = Bugsnag.getPlugin('vue');
    app.use(bugsnagVue as BugsnagPluginVueResult);
}

VueLogger.install(app, {
    isEnabled: process.env.VUE_APP_SH_ENV !== 'Production',
    logLevel: LogLevels.DEBUG,
    separator: "|",
    showConsoleColors: true,
    showLogLevel: false,
    showMethodName: false,
    stringifyArguments: false,
})

app
    .component('Select2', Select2)
    .component('Popper', Popper)
    .use(Maska)
    //.use(vfmPlugin)
    .use(VueLoaders)
    .use(VueSpinners)
    .use(store)
    .use(createPinia())
    .use(ElementPlus)
    .use(Toast)
    .use(VueCookies)
    .use(IonicVue)
    .use(plugin, defaultConfig({
        config: {
            classes: {
                input: '$reset form-control',
                label: '$reset fs-6 fw-bold form-label mb-2'
            }
        }
    }))
    .use(router)
    .use(VueQueryPlugin)
    .use(auth0).mount("#app");

//app.use(store, key);
