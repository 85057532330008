
import {defineComponent, onMounted} from "vue";
import {useApplication} from "@/store/pinia/applicationStore";
import ApplicationWrapper from "@/ApplicationWrapper.vue";
import {auth0} from "@/main";
import {isPlatform} from "@ionic/vue";

export default defineComponent({
    components: {ApplicationWrapper},
    setup() {
        const applicationStore = useApplication()
        onMounted(() => {
            //console.log('[SH] App Mounted')
            window.initializeKT();
        });
        return {applicationStore}
    },
    methods: {
        signOut() {
            auth0.logout({
                logoutParams: {
                    returnTo: isPlatform('capacitor') ? process.env.VUE_APP_CAP_ENDSESSION : process.env.VUE_APP_AUTH0_APPLOGOUT
                }
            });
        }
    },
    computed: {
        currentRoute() {
            return this.$route.fullPath
        },
        isStart() {
            return this.$route.name === 'start'
        },
        isSignUp() {
            return this.$route.name === 'sign-up'
        },
        isLoading(): boolean {
            return auth0.isLoading.value
        },
        isAuthenticated(): boolean {
            return auth0.isAuthenticated.value
        },
        appIsLoaded(): boolean {
            return this.applicationStore.isLoaded
        }
    },
});
