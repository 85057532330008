export const applicationState =  {
    namespaced: true,
    state: () => ({
        isLoaded: false,
        isAuthenticated: false,
        route: ''
    }),
    actions: {},
    //commit('application/setLoadingState')
    mutations: {
        setLoadingState: function (state: any, loadingState: boolean){
            //console.log('setLoadingState', loadingState)
            state.isLoaded = loadingState
        },
        setAuthenticatedState:  function (state: any, authenticatedState: boolean){
            //console.log('setAuthenticatedState', authenticatedState)
            state.isAuthenticated = authenticatedState
        },
        setRoute(state: any, route){
            state.route = route
        },
    },
    getters: {
        getAuthenticatedState(state){
            return state.isAuthenticated
        },
        getRoute(state){
            return state.route
        }
    }
}
