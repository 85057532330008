import {apiClient} from "@/modules/apiClient";
import {User} from "@/models/user/User";
import {CreateAccountCommand} from "@/models/user/CreateAccountCommand";
import {AxiosPromise, AxiosResponse} from "axios";
import {AvatarViewModel} from "@/models/AvatarViewModel";
import {AccountCommand} from "@/models/user/AccountCommand";
import {RemovePersonCommand} from "@/models/people/RemovePersonCommand";
import {Invitation} from "@/models/user/Invitation";
import {RequestAccessCommand} from "@/models/user/RequestAccessCommand";
import {InvitationResponseCommand} from "@/models/user/InvitationResponseCommand";
import {NotificationItem} from "@/models/notifications/Notification";
import {SendFeedbackCommand} from "@/models/SendFeedbackCommand";
import {UserMeasurement} from "@/models/user/UserMeasurement";
import MeasurementSettings from "@/components/SelfContained/MeasurementSettings.vue";
import {UpdateAccountTermsCommand} from "@/models/user/UpdateAccountTermsCommand";
import {UserMetadata} from "@/models/user/UserMetadata";

export const UserService = {
    fetch(): Promise<AxiosResponse<User>> {
        return apiClient.get('/')
    },
    fetchMetadata(): Promise<AxiosResponse<UserMetadata>> {
        return apiClient.get('/metadata')
    },
    reconcile(): Promise<AxiosResponse> {
        return apiClient.get('/reconcile')
    },
    fetchInvitations(): Promise<AxiosResponse<Array<Invitation>>> {
        return apiClient.get('/invitations')
    },
    fetchNotifications(): Promise<AxiosResponse<Array<NotificationItem>>> {
        return apiClient.get('/notifications')
    },
    markReadNotifications(notificationIds: Array<string>): Promise<AxiosResponse> {
        return apiClient.put('/notifications', {notificationIds: notificationIds})
    },
    create(command: CreateAccountCommand): Promise<AxiosResponse<User>> {
        return apiClient.post('/', command)
    },
    changePassword(passwordCommand): Promise<AxiosResponse> {
        return apiClient.put('/password', passwordCommand)
    },
    update(command: AccountCommand): Promise<AxiosResponse> {
        return apiClient.put('/', command);
    },
    updateTerms(command: UpdateAccountTermsCommand): Promise<AxiosResponse> {
        return apiClient.put('/terms', command);
    },
    uploadPhoto(formData): Promise<AxiosResponse<AvatarViewModel>> {
        return apiClient.put('/photo', formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    removePhoto(): Promise<AxiosResponse> {
        return apiClient.delete('/photo');
    },
    removeSelf(command: RemovePersonCommand): Promise<AxiosResponse> {
        return apiClient.put('/people/remove/self', command)
    },
    requestAccess(command: RequestAccessCommand): Promise<AxiosResponse> {
        return apiClient.post('/invitations', command)
    },
    respondToInvitation(command: InvitationResponseCommand): Promise<AxiosResponse> {
        return apiClient.put('/invitations', command)
    },
    sendFeedback(command: SendFeedbackCommand): Promise<AxiosResponse> {
        return apiClient.post('/feedback', command)
    },
    fetchMeasurements(): Promise<AxiosResponse<UserMeasurement>> {
        return apiClient.get('/measurements')
    },
    upsertMeasurements(command: UserMeasurement): Promise<AxiosResponse<UserMeasurement>> {
        return apiClient.put('/measurements', command)
    }
}
